
import backend from "../api/api";
import { store } from "../store";

const getConfig = () => {
    const token = store.getState().auth.token;

    const config = {
        headers: {
            Authorization: `Token ${token}`,
        },
    };

    return config;
};

export const createPromotion = (formValues) => async (dispatch) => {
    try {
        const response = await backend.post(
            "api/hrm/promotion/",
            { ...formValues },
            getConfig()
        );
        if (response.status === 201) {
            return response.data
        }
    } catch (error) {
        alert(error);
    }
};

export const getFilteredPromotion = (salary__id) => async (dispatch) => {
    try {
        const response = await backend.get(
            `api/hrm/promotion/?salary__id=${salary__id}`,
            getConfig()
        );
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        console.log(error);
    }
};


export const deletePromotion = (id) => async (dispatch) => {
    try {
        const response = await backend.delete(
            `api/hrm/promotion/${id}/`,
            getConfig()
        );
        if (response.status === 204) {
            return true
        }
    } catch (error) {
        alert(error);
    }
};

